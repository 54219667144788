import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios'
import  VueAxios from 'vue-axios'
import { createRouter, createWebHistory } from 'vue-router'; // 注意这里的包名是vue-router

const routes = [
    {
        path: '/',
        props: (route) => ({ share: route.query.share, code: route.query.code })
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// 创建 Vue 应用实例
const app = createApp(App);

// 使用 VueAxios 插件，并将 axios 挂载到实例上
app.use(VueAxios, axios);

// 使用路由插件
app.use(router);

// 挂载到 #app 元素
app.mount('#app');