<template>
  <div class="container">
    <div id="headcont">
      <div id="rightnav">

        <div @click="toggleMusic" id="music-btn" class="music-btn">
          <img :src="isMusicPlaying ? require('@/assets/img/musicOn.png') : require('@/assets/img/musicOff.png')"
            alt="Music Control">
        </div>


        <div @click="getMyPrize" id="my-btn" class="my-btn">
          <p>
            <img width="100%" src="../assets/img/my1.png">
          </p>
        </div>
        <audio id="myaudio" src="https://file-center.ehuigu.com/lucky-open/bj.mp3" preload="auto" loop="true" autoplay="autoplay"></audio>
      </div>
    </div>
    <!--大转盘-->
    <div class="lucky-wheel">
      <div class="lucky-title"></div>
      <div class="wheel-main">
        <div class="wheel-pointer" @click="beginRotate()"></div>
        <div class="wheel-bg" :style="rotateStyle">
          <div class="prize-box">
            <div class="prize-list">
              <div class="prize-item" v-for="(item, index) in prizeList" :key="index" :style="item.style">
                <span style="color:#000000">
                  <b style="display:block;height:5.2vw;overflow:hidden;">{{ item.name }}</b>
                  <!-- <i>{{item.description}}</i> -->
                  <img :src="item.imgUrl" style="width: 15vw; height: auto;">
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="finger finger_rainbow">
        </div>
      </div>
      <div v-if="secretKey" class="lot-name">
        <span id="username"></span>参与者姓名：{{ secretKey }} <span v-if="secretKey" @click="secretKeyShow = true"
          style="color: blue;">修改</span>
      </div>
      <div class="lot-num">
        <p><span id="usernamechk"></span>已有{{ allUserPrizesNum }}人参与</p>
      </div>
    </div>
    <!--中奖公告-->
    <div id="common-debox">
      <div class="common-boxcontent common-boxwhite">
        <div class="common-box">
          <div class="common-title-red">中奖公告：</div>
          <div class="common-Detail">
            <div class="common-scroll">
              <p></p>
              <div>
                <ul>
                  <li v-for="item in allUserPrizes" :key="item.luckyId">
                    <div class="common-scroll-img"><img :src="item.wechatImgUrl"></div>
                    <div class="common-scroll-cont">
                      <p class="common-scroll-cont-up">
                        <span class="common-scroll-cont-lt">
                          {{ item.wechatNickname }}<br>
                          <span class="common-scroll-cont-dn">{{ item.prizesExtra2 }}</span>
                        </span>
                        <span class="common-scroll-cont-rg">{{ item.drawTime.slice(0, 16).replace('T', ' ') }}</span>
                      </p>
                    </div>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="common-boxcontent common-boxwhite" style="position: relative; float: bottom;">
        <div class="common-box">
          <div class="common-title-red">奖品设置：</div>
          <div class="common-Detail">
            <div class="common-list prize"
              style="position: relative; border-radius: 0; margin: 0; left: 0; padding-bottom: 2%;">
              <dl v-for="item in prizeList" :key="item.name">
                <dt class="common-box-imgdt">
                  <img :src="item.imgUrl" />
                </dt>
                <dd>
                  <b>{{ item.name }}</b><br />
                  <span>{{ item.description }}</span><br />
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>


      <!-- <div class="common-boxcontent common-boxwhite" style="position: relative; float: bottom; display:block">
        <div class="common-box">
          <div class="common-title-red">礼品标准：</div>
          <div class="common-Detail">
            <p>一等奖：1名，价值1000元礼品；</p>
            <p>二等奖：10名，价值500元礼品；</p>
            <p>三等奖：30名，价值200元礼品；</p>
            <p>参与奖：若干，1至5元随机微信红包。</p>
          </div>
        </div>
      </div> -->
      <br><br>
    </div>
    <div class="toast" v-if="prize">
      <div class="toast-container">
        <img :src="toastIcon" class="toast-picture" />
        <div class="close" @click="closeToast()"></div>
        <div class="toast-title">{{ toastTitle }}</div>
        <div class="toast-btn">
          <div class="toast-cancel" @click="closeToast">关闭</div>
        </div>
      </div>
    </div>
    <div class="toast-my" v-if="myPrize">
      <div class="common-boxcontent common-boxwhite">
        <div class="common-box">
          <div class="common-title-red" style="width: 50%">我的中奖信息：</div>
          <div class="common-Detail">
            <div class="common-scroll">
              <p v-if="!myPrizes || myPrizes.length === 0">
                暂无信息
              </p>
              <div v-else>
                <ul>
                  <li v-for="item in myPrizes" :key="item.luckyId" style="border-bottom: none !important;">
                    <div class="common-scroll-img"><img :src="item.wechatImgUrl" style="width: 10vw;"></div>
                    <div class="common-scroll-cont" style="width: calc(100% - 12.8vw);">
                      <p class="common-scroll-cont-up">
                        <span class="common-scroll-cont-lt" style="font-size: 14px; width: 100% !important;">
                          {{ item.wechatNickname }}<br>
                          <span class="common-scroll-cont-dn" style="font-size: 12px; color: red;">{{ item.prizesExtra2 }}</span><br>
                          <span class="common-scroll-cont-rg" style="font-size: 12px; float: left !important; width: 100%;">{{ item.drawTime.slice(0,
                          16).replace('T', ' ') }}</span>
                        </span>
                      </p>
                      <!-- <p class="common-scroll-cont-up">
                        <span class="common-scroll-cont-rg" style="font-size: 12px;">{{ item.drawTime.slice(0,
                          16).replace('T', ' ') }}</span>
                      </p> -->
                      <!-- <p class="common-scroll-cont-dn">{{ item.prizesExtra2 }}</p> -->
                    </div>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="toast-btn" style="border-top: 1px dashed rgba(60,57,57,.65);">
            <div class="toast-cancel" style="margin: 5px 0 10px 0;" @click="myPrize = false">关闭</div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast-my" v-if="secretKeyShow">
      <div class="common-boxcontent common-boxwhite">
        <div class="common-box">
          <div class="common-title-red" style="width: 100%">请填写员工本人姓名+身份证号码后四位，如张三0103</div>
          <div class="common-Detail" style="padding: 10px 10px 0 10px;">
            <input v-model="secretKey" type="text"
              style="width: calc(100% - 10px); border: 1px solid #e3e3e3; text-align: center;">
          </div>
          <div class="toast-btn" style="padding: 10px;">
            <div class="toast-cancel" style="margin-right: 20px;" @click="secretKeyShow = false; secretKey = null">关闭
            </div>
            <div class="toast-cancel" @click="getDrawLuckyPreCheck">保存</div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast-mask" v-if="prize || myPrize || secretKeyShow"></div>
  </div>
</template>
<script>
// import VConsole from 'vconsole'
import { prizeList } from './config'
import axios from 'axios';
// import {getLuckyInfo} from "@/api/common";
const CIRCLE_ANGLE = 360

const config = {
  // 总旋转时间
  duration: 4000,
  // 旋转圈数
  circle: 8,
  mode: 'ease-in-out'
}

export default {
  name: 'lucky-wheel',
  data() {
    return {
      count: 1, // 剩余抽奖次数
      duration: 3000, // 转盘旋转时间
      prizeList: [], // 奖品列表
      rotateAngle: 0, // 旋转角度
      index: 0,
      prize: null,
      myPrize: false,
      secretKeyShow: false,
      luckyId: "",
      userId: "",
      secretKey: "",
      myPrizes: [],
      allUserPrizes: [],
      allUserPrizesNum: 0,
      winningInfo: '',
      displayItems: [], // 当前显示的三条数据
      currentIndex: 0, // 当前显示数据的索引
      isMusicPlaying: true, // 音乐是否正在播放
    };
  },
  // watch: {
  //   $route(to) {
  //     // 获取 share 参数的值
  //     const share = to.query.share;
  //     // 获取 code 参数的值
  //     const code = to.query.code;
  //     this.userId = JSON.parse(localStorage.getItem('userInfo'))?.userId
  //     // 判断 share 是否不为空
  //     if (share) {
  //       this.getLuckyInfo(share)
  //     } else {
  //       alert("请使用")
  //     }
  //     // 判断 code 是否不为空
  //     if (!this.userId) {
  //       this.handleRedirect(code)
  //     }
  //   }
  // },
  mounted() {
    // const vConsole = new VConsole();
    // window.vConsole = vConsole;

    // 这里使用模拟数据
    this.prizeList = this.formatPrizeList(prizeList)
    // 初始化显示数据
    // this.updateDisplayItems();

    // 定时切换显示数据
    // setInterval(() => {
    //   // this.currentIndex = (this.currentIndex + 3) % this.allUserPrizes.length;
    //   // this.updateDisplayItems();
    //   // this.currentIndex = (this.currentIndex + 1) % this.allUserPrizes.length;
    //   // this.updateDisplayItems();
    //   this.getAllUserDrawsPrizes();
    // }, 6000);

    // 初始化音乐状态
    this.isMusicPlaying = true;
    // 根据音乐状态播放或暂停音乐
    if (this.isMusicPlaying) {
      this.playMusic();
    } else {
      this.pauseMusic();
    }
    // 监听触摸事件
    document.addEventListener('touchstart', this.handleFirstTouch);

  },
  beforeUnmount() {
    // 组件销毁时移除事件监听器
    document.removeEventListener('touchstart', this.handleFirstTouch);
  },
  created() {
    // 初始化一些值
    this.angleList = []
    // 是否正在旋转
    this.isRotating = false
    // 基本配置
    this.config = config;


    // 从缓存中获取shareId
    const cachedShareId = localStorage.getItem('shareId');

    // 获取URL参数
    const urlParams = new URLSearchParams(window.location.search);
    const shareIdFromUrl = urlParams.get('share');
    const code = urlParams.get('code');

    if (cachedShareId) {
      // 如果缓存中有shareId，使用它并获取信息
      this.getLuckyInfo(cachedShareId);
    } else if (shareIdFromUrl) {
      // 如果缓存中没有但URL中有share参数，存储到缓存并获取信息
      localStorage.setItem('shareId', shareIdFromUrl);
      this.getLuckyInfo(shareIdFromUrl);
    } else {
      // 如果缓存和URL中都没有shareId，提示用户
      alert('请通过分享的链接进入！');
    }

    // 从缓存中获取userId
    const cachedUserId = localStorage.getItem('userId');
    if (!cachedUserId && !code) {
      // alert("x" +this.userId + ":"+code)
      // 如果没有userId，触发微信授权
      this.getOpenWeixin();

    }

    if (!cachedUserId && code) {
      // alert("x2" +this.userId + ":"+code)
      // 如果有code参数，处理微信授权回调
      this.handleRedirect(code);
    }

  },
  computed: {
    rotateStyle() {
      return `
        -webkit-transition: transform ${this.config.duration}ms ${this.config.mode};
        transition: transform ${this.config.duration}ms ${this.config.mode};
        -webkit-transform: rotate(${this.rotateAngle}deg);
            transform: rotate(${this.rotateAngle}deg);`
    },
    toastTitle() {
      return this.prize && this.prize.prizeId
        ? this.winningInfo
        : "未中奖";
    },
    toastIcon() {
      return this.prize && this.prize.prizeId
        ? require("../assets/img/congratulation.png")
        : require("../assets/img/sorry.png");
    }
  },
  methods: {
    updateDisplayItems() {
      // const endIndex = this.currentIndex + 3;
      // this.displayItems = this.allUserPrizes.slice(this.currentIndex, endIndex);
      const totalItems = this.allUserPrizes.length;
      const itemsToShow = 3;
      const startIndex = this.currentIndex;
      const endIndex = Math.min(startIndex + itemsToShow, totalItems);
      this.displayItems = this.allUserPrizes.slice(startIndex, endIndex);
    },
    async initPrizeList() {
      // 这里可以发起请求，从服务端获取奖品列表
      axios({
        method: 'get',
        url: 'https://lucky.ehuigu.com/RandomLucky/lucky/getPrizes',
        params: { luckyId: this.luckyId || localStorage.getItem('luckyId') }
      })
        .then(response => {
          console.log('initPrizeList', response)
          if (response.data.success) {
            this.prizeList = this.formatPrizeList(response.data.data)
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    async getAllUserDrawsPrizes() {
      // 这里可以发起请求，从服务端获取奖品列表
      axios({
        method: 'get',
        url: 'https://lucky.ehuigu.com/RandomLucky/lucky/getAllUserDrawsPrizes',
        params: { luckyId: this.luckyId || localStorage.getItem('luckyId') }
      })
        .then(response => {
          console.log('getAllUserDrawsPrizes', response)
          if (response.data.success) {
            this.allUserPrizes = response.data.data.list
            this.allUserPrizesNum = response.data.data.total
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    async getDrawLuckyPreCheck() {
      if (this.secretKey) {
        // 这里可以发起请求，从服务端获取奖品列表
        axios({
          method: 'post',
          url: 'https://lucky.ehuigu.com/RandomLucky/lucky/drawLuckyPreCheck',
          data: { luckyId: this.luckyId || localStorage.getItem('luckyId'), userId: this.userId, secretKey: this.secretKey }
        })
          .then(response => {
            if (response.data.success) {
              this.secretKeyShow = false
              this.beginRotate()
            } else {
              alert(response.data.errorMessage)
            }
          })
          .catch(error => {
            console.error(error);
            alert("请填写员工本人姓名+身份证号码后四位，如张三0103")
          });
      }
    },
    getMyPrize () {
      this.myPrize = true
      this.getUserDrawsPrizes()
    },
    async getUserDrawsPrizes() {
      // 这里可以发起请求，从服务端获取奖品列表
      axios({
        method: 'get',
        url: 'https://lucky.ehuigu.com/RandomLucky/lucky/getUserDrawsPrizes',
        params: { luckyId: this.luckyId || localStorage.getItem('luckyId'), userId: this.userId || localStorage.getItem('userId') }
      })
        .then(response => {
          console.log('getUserDrawsPrizes', response)
          if (response.data.success) {

            this.myPrizes = []
            if (response.data.data?.luckyId) {
              this.myPrizes.push(response.data.data)
            } 
        
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    // 格式化奖品列表，计算每个奖品的位置
    formatPrizeList(list) {
      // 记录每个奖的位置
      const angleList = []

      const l = list.length
      // 计算单个奖项所占的角度
      const average = CIRCLE_ANGLE / l

      const half = average / 2

      // 循环计算给每个奖项添加style属性
      list.forEach((item, i) => {

        // 每个奖项旋转的位置为 当前 i * 平均值 + 平均值 / 2
        const angle = -((i * average) + half)
        // 增加 style
        item.style = `-webkit-transform: rotate(${angle}deg);
                      transform: rotate(${angle}deg);`

        // 记录每个奖项的角度范围
        angleList.push((i * average) + half)
      })

      this.angleList = angleList

      return list
    },
    getOpenWeixin() {
      const url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb217c363462e2b8f&redirect_uri=https://lucky.ehuigu.com&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';
      window.location.href = url
    },
    async beginRotate() {
      // const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      // const code = this.$route.query.code
      // if (!code) {
      let userId = localStorage.getItem('userId')
      console.log('userId', userId)
      this.userId = userId
      if (userId) {
        if (this.secretKey) {
          axios({
            method: 'post',
            url: 'https://lucky.ehuigu.com/RandomLucky/lucky/drawLucky',
            data: { luckyId: this.luckyId || localStorage.getItem('luckyId'), userId: this.userId, secretKey: this.secretKey }
          })
            .then(response => {
              if (response.data.success) {
                console.log('response.dataresponse.dataresponse.data', response.data)
                let prizesId = response.data.data.prizeId || response.data.data.prizesId
                this.winningInfo = response.data.data.winningInfo
                console.log('this.prizesId', prizesId)
                console.log('this.prizeList', this.prizeList)
                let matchingObject = {}
                this.prizeList.forEach(item => {
                  if (item.prizeId === prizesId) {
                    console.log('找到了匹配的奖品:', item.prizeId, item);
                    matchingObject = item
                  }
                });
                console.log('获取抽奖奖品', matchingObject)
                if (matchingObject) {
                  console.log('进入抽奖动画', matchingObject)
                  // 添加次数校验
                  if (this.count === 0) return
                  // 开始抽奖
                  // 这里这里向服务端发起请求，得到要获得的奖
                  // 可以返回下标，也可以返回奖品 id，通过查询 奖品列表，最终得到下标
                  // 随机获取下标中间
                  this.index = matchingObject.sort;
                  console.log('第几个奖品', this.index)
                  // 减少剩余抽奖次数
                  this.count--
                  console.log('进入抽奖动画', this.count)
                  // 开始旋转
                  this.rotating()
                  setTimeout(() => {
                      this.getAllUserDrawsPrizes();
                  }, 10000);
                }
              } else {
                alert(response.data.errorMessage)
              }
            })
            .catch(error => {
              console.error(error);
            });
        } else {
          this.secretKeyShow = true
        }
      }
      // else {
      //   // 使用userInfo进行后续操作
      //   this.getOpenWeixin()
      // }
    },
    async getLuckyInfo(shareKey) {
      // 在这里处理shareKey，例如发送给服务器进行后续操作
      axios({
        method: 'get',
        url: 'https://lucky.ehuigu.com/RandomLucky/lucky/getLuckyInfo',
        params: { shareKey: shareKey }
      })
        .then(response => {
          console.log('response', response)
          if (response.data.success) {
            this.luckyId = response.data.data.luckyId
            localStorage.setItem('luckyId', this.luckyId)
            // 获取奖品列表
            this.initPrizeList();
            this.getAllUserDrawsPrizes();
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    async handleRedirect(code) {
      axios({
        method: 'post',
        url: 'https://lucky.ehuigu.com/RandomLucky/wechatSSO/wechatAuth',
        data: { code: code, luckyId: this.luckyId || localStorage.getItem('luckyId'), forbidFlag: false }
      })
        .then(response => {
          if (response.data.success) {
            let userLocalInfo = JSON.stringify(response.data)
            this.userId = response.data.data.userId
            localStorage.setItem('userId', response.data.data.userId)
            localStorage.setItem('userInfo', userLocalInfo)
            this.initPrizeList();
            this.getAllUserDrawsPrizes();
            this.getUserDrawsPrizes();

            // 从缓存中获取shareId
            const cachedShareId = localStorage.getItem('shareId');
            if (cachedShareId) {
              // alert("X3" + cachedShareId)
              // 如果有缓存的shareId，构建新URL并重定向
              const newUrl = window.location.origin + window.location.pathname + `?share=${cachedShareId}`;
              // const newUrl = 'https://lucky.ehuigu.com/' + `?share=${cachedShareId}`;

              window.location.href = newUrl;
            } else {
              // 如果没有缓存的shareId，直接重定向到原始路径
              // window.location.href = window.location.origin + window.location.pathname;
              alert("请重新进入系统!")
            }
          } else {
            alert("请联系管理员!")
          }
        })
        .catch(error => {
          console.error(error);
        });
      // 使用userInfo进行后续操作
    },
    random(max, min = 0) {
      return parseInt(Math.random() * (max - min + 1) + min)
    },
    rotating() {
      const { isRotating, angleList, config, rotateAngle, index } = this

      if (isRotating) return

      this.isRotating = true

      // 计算角度
      const angle =
        // 初始角度
        rotateAngle +
        // 多旋转的圈数
        config.circle * CIRCLE_ANGLE +
        // 奖项的角度
        angleList[index] -
        (rotateAngle % CIRCLE_ANGLE) - 45


      this.rotateAngle = angle
      console.log('获取角度', this.rotateAngle)

      // 旋转结束后，允许再次触发
      setTimeout(() => {

        this.rotateOver()
      }, config.duration + 1000)


    },
    rotateOver() {
      this.getAllUserDrawsPrizes();
      this.isRotating = false

      this.prize = this.prizeList[this.index]

      console.log('结束', this.prize)
      console.log(this.prize, this.index)
    },
    //关闭弹窗
    closeToast() {
      this.prize = null;
    },
    preventZoom(event) {
      // 阻止默认行为，防止浏览器缩放
      event.preventDefault()
    },
    onInputFocus() {
      // 输入框聚焦时，添加样式以防止页面缩放
      document.body.style.touchAction = 'none'
    },

    handleFirstTouch() {
      if (!this.isMusicPlayed) {
        this.playMusic();
        this.isMusicPlayed = true; // 设置标志，表示音乐已播放
      }
    },
    // 播放音乐
    playMusic() {
      const audioElement = document.getElementById('myaudio');
      if (audioElement) {
        audioElement.play();
        this.isMusicPlaying = true;
      }
    },
    // 暂停音乐
    pauseMusic() {
      const audioElement = document.getElementById('myaudio');
      if (audioElement) {
        audioElement.pause();
        this.isMusicPlaying = false;
      }
    },
    // 切换音乐播放状态
    toggleMusic() {
      if (this.isMusicPlaying) {
        this.pauseMusic();
      } else {
        this.playMusic();
      }
    },
  }
};
</script>
<style scoped>
/* 添加以下样式以防止页面缩放 */
body {
  touch-action: manipulation;
}

.container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.lucky-wheel {
  width: 100%;
  background: #C40120 url("https://file-center.ehuigu.com/lucky-open/bj.jpg") no-repeat top center;
  padding-top: 40vw;
  padding-bottom: 40vw;
  background-size: 100%;
  min-height: 20rem;
}

/*.lucky-title {*/
/*  width: 100%;*/
/*  height: 8.125rem;*/
/*  background: url("../assets/img/lucky_title.png") no-repeat center top;*/
/*  background-size: 100%;*/
/*}*/
.wheel-main {
  margin: 0 auto;
  position: relative;
  width: 90vw;
  height: 90vw;
}

.lot-name {
  height: 6vw;
  font-size: 3vw;
  line-height: 6vw;
  text-align: center;
  background: #ffffff;
  color: #333333;
  width: 52vw;
  /*display: inline-block;*/
  border-radius: 3vw;
  margin: 0 auto;
  margin-top: 5vw;
  padding-bottom: 5vw;
}

.lot-num {
  height: 6vw;
  font-size: 3vw;
  line-height: 6vw;
  text-align: center;
  color: #fff;
  background: #ffbe04;
  width: 62vw;
  border-radius: 3vw;
  margin: 5vw auto;
  padding-bottom: 5vw;
}

.wheel-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url("../assets/img/draw_wheel.png") no-repeat center top;
  background-size: 100%;
  color: #fff;
}

.wheel-pointer {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 19vw;
  height: 25vw;
  background: url("../assets/img/draw_btn.png") no-repeat center top;
  background-size: 100%;
  transform: translate3d(-50%, -50%, 0);
}

.wheel-bg div {
  text-align: center;
}

.prize-box {
  width: 81vw;
  height: 81vw;
  position: absolute;
  top: 50%;
  background: #fff;
  margin-top: -40.5vw;
  left: 50%;
  margin-left: -40.5vw;
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(45deg);
}

.prize-list {
  width: 76vw;
  height: 76vw;
  position: absolute;
  top: 50%;
  background: #fff;
  margin-top: -38vw;
  left: 50%;
  margin-left: -38vw;
  border-radius: 50%;
  overflow: hidden;
}

.prize-list .prize-item {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  margin-left: -37vw;
  border-left: 37vw solid transparent;
  border-right: 37vw solid transparent;
  border-top: 37vw solid #FDF6DA;
  transform-origin: 37vw 38vw;
  position: absolute;
  left: 50%;
  top: 0;
  color: #000;
  width: 0;
  height: 0;
  line-height: 1.2;
  border-radius: inherit;
  /* 通过继承 .prize-list 的 border-radius */
  /*overflow: inherit; !* 通过继承 .prize-list 的 overflow *!*/
}

.prize-list .prize-item span {
  display: block;
  position: absolute;
  text-align: center;
  font-weight: bold;
  top: -35vw;
  width: 16vw;
  left: 50%;
  margin-left: -8vw;
  z-index: 90;
  /*text-shadow: 0 0 0.1rem #fff;*/
  font-size: 3.8vw;
  overflow: hidden;
}

.prize-list .prize-item span b {
  display: block;
  height: 4.2vw;
  overflow: hidden;
}

.prize-list .prize-item span i {
  display: block;
  font-size: 3.4vw;
  font-style: normal;
  overflow: hidden;
  /*text-overflow: ellipsis;*/
  white-space: nowrap;
  font-weight: normal;
}

.prize-pic img {
  display: block;
  margin: 0.1rem auto 0;
  width: 10vw;
  height: 10vw;
  border-radius: 1vw;
}

.prize-count {
  font-size: 0.875rem;
}

.prize-type {
  font-size: 0.75rem;
}

.main {
  position: relative;
  width: 100%;
  min-height: 14.25rem;
  background: rgb(243, 109, 86);
  padding-bottom: 1.6875rem;
}

.main-bg {
  width: 100%;
  height: 6.5625rem;
  position: absolute;
  top: -3.4375rem;
  left: 0;
  background: url("../assets/img/luck_bg.png") no-repeat center top;
  background-size: 100%;
}

.bg-p {
  width: 100%;
  height: 2.95rem;
  background: rgb(252, 207, 133);
}

.content {
  position: absolute;
  top: 0.175rem;
  left: 0;
  background: rgb(243, 109, 86);
  width: 100%;
  height: 5.1875rem;
  font-size: 1.125rem;
  color: #ffeb39;
  padding-left: 6.75rem;
}

.content div {
  text-align: left;
}

.tip {
  position: relative;
  margin: 2.5rem auto 0;
  width: 17.5rem;
  border: 1px solid #fbc27f;
}

.tip-title {
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1rem;
  color: #fccc6e;
  background: rgb(243, 109, 86);
  padding: 0.3125rem 0.625rem;
}

.tip-content {
  padding: 1.5625rem 0.625rem;
  font-size: 0.875rem;
  color: #fff8c5;
  line-height: 1.5;
}

.toast-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20000;
  transform: translate(-50%, -50%);
  /* width: 80vw; */
  width: 89%;
  background: #fff;
  border-radius: 3vw;
  padding: 3vw;
  background-color: rgb(252, 244, 224);
}

.toast-my {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20000;
  transform: translate(-50%, -50%);
  width: 80vw;
  background: #fff;
  border-radius: 3vw;
  padding: 3vw;
  background-color: rgb(252, 244, 224);
}

.toast-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px dotted #fccc6e;
}

.toast-picture {
  position: absolute;
  top: -20vw;
  left: -1vw;
  width: 80vw;
  height: auto;
}

.toast-pictrue-change {
  position: absolute;
  top: -1.5rem;
  left: -1.375rem;
  width: 17.5rem;
  height: 3.125rem;
}

.toast-title {
  padding: 4vw 0;
  font-size: 4vw;
  color: #fc7939;
  text-align: center;
}

.toast-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1vw;
}

.toast-btn div {
  background-image: -moz-linear-gradient(-18deg,
      rgb(242, 148, 85) 0%,
      rgb(252, 124, 88) 51%,
      rgb(252, 124, 88) 99%);

  background-image: -ms-linear-gradient(-18deg,
      rgb(242, 148, 85) 0%,
      rgb(252, 124, 88) 51%,
      rgb(252, 124, 88) 99%);
  background-image: -webkit-linear-gradient(-18deg,
      rgb(242, 148, 85) 0%,
      rgb(252, 124, 88) 51%,
      rgb(252, 124, 88) 99%);
  box-shadow: 0px 4px 0px 0px rgba(174, 34, 5, 0.7);
  width: 40vw;
  height: 5vw;
  border-radius: 1.875vw;
  text-align: center;
  line-height: 5vw;
  font-size: 3vw;
  color: #fff;
}

.close {
  position: absolute;
  top: -0.9375rem;
  right: -0.9375rem;
  width: 2rem;
  height: 2rem;
  background: url("../assets/img/close_store.png") no-repeat center top;
  background-size: 100%;
}

.music-btn {
  z-index: 999;
  position: fixed;
  top: 5vw;
  right: 1vw;
  width: 8vw;
  height: 8vw;
}

.music-btn img {
  width: 8vw;
  height: 8vw;
}

.my-btn {
  z-index: 999;
  position: fixed;
  top: 16vw;
  right: 0;
  width: 12vw;
  height: 6.4vw;
}

.my-btn p {
  width: 100%;
  height: 100%;
}

.complain-btn {
  z-index: 999;
  position: fixed;
  top: 24vw;
  right: 0;
  width: 12vw;
  height: 6.4vw;
}

.complain-btn p {
  width: 100%;
  height: 100%;
}

#common-debox {
  width: 100%;
  position: relative;
  padding: 0 2vw;
  margin: 5vw auto;
  font-size: 3.5vw;
  overflow-x: hidden;
  margin-top: -42vw;
}

#common-debox img {
  width: 10vw;
}

.common-boxwhite {
  background-color: #ffffff;
}

.common-boxcontent {
  margin: 2vw 2vw 0;
  border-radius: 1vw;
  padding: 1vw;
  box-shadow: 10px rgb(0 0 0 / 50%);
}

.common-box {
  border-radius: 1vw;
  border: 2px dashed rgba(0, 0, 0, 0.3);
}

.common-box .common-title-red {
  padding: 1.5vw;
  background-color: rgb(216 12 7);
  border-radius: 0.1rem 0.1rem 0.1rem 0;
  color: #ffffff;
  margin: 0rem;
  width: 24%;
}

.common-box .common-Detail {
  margin: 0.2rem;
  padding: 0.1rem;
  overflow-x: hidden;
}

.common-list {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.common-list dl {
  float: left;
  /*width: 31.33%;*/
  width: calc(50% - 5px);
  padding: 0.05rem;
  text-align: center;
  background: #fff;
  border-radius: 0.05rem;
  color: #000;
}

.common-box-imgdt {
  overflow: hidden;
}

.common-box-imgdt img {
  /*width: 80%;*/
  height: 18vw;
  width: auto !important;
  /*margin: 0.1rem 0.05rem;*/
}

.form li {
  margin: 0.2rem 0;
}

input.txt,
input.btn,
.a-btn {
  width: 100%;
  outline: none;
  font-size: 0.2rem;
  background: #fff;
  border: 0.01rem solid #fd4c4c;
  height: 0.6rem;
  color: #fd4c4c;
  padding: 0 2%
}

input.btn,
.a-btn {
  padding: 0;
  background: #fd4c4c;
  color: #fff;
  font-size: 0.36rem
}

.a-btn {
  display: block;
  margin: 0.3rem auto 0;
  line-height: 0.8rem;
  height: 0.8rem;
  border-radius: 0.2rem;
}

.w-btn {
  font-size: 0.6rem;
  font-weight: 100;
  color: #fd4c4c;
  position: absolute;
  right: 5px;
  top: -10px;
}


#time {
  font-size: 0.3rem;
  font-family: Arial, Helvetica, sans-serif;
}


input.txt {
  font-size: 0.2rem;
  height: 0.6rem;
  width: 100%;
  border: 0;
  background: #eee;
  outline: none;
  padding: 0 3%;
}

input.f-btn {
  border: 0;
  font-size: 0.3rem;
  width: 100%;
  height: 0.6rem;
  border-radius: 0.03rem;
  background: #ffbe04;
  color: #fff;
  outline: none;
}

.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 998;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
}

.opwin {
  width: 80%;
  top: 15%;
  text-align: center;
  position: absolute;
  background: #fff;
  border-radius: 0.1rem;
  left: 10%;
  z-index: 999;
  display: none;
}

.opwin div {
  padding: 0.5rem;
}

.opwin h1 {
  text-align: center;
  font-size: 0.48rem;
  margin-bottom: 0.2rem;
}

.opwin h3 {
  font-weight: bold;
  font-size: 0.48rem;
  margin-top: 0.2rem;
  color: red;
  text-align: center;
}

.pd3 {
  padding: 0 0.3rem;
}

.pd6 {
  padding: 0 0.6rem;
}

.pd12 {
  padding: 0 1.2rem;
}

.resultprize1 {
  /*background: url(../image/prizemsg1.png) no-repeat top center;*/
}

.resultprize2 {
  /*background: url(../image/prizemsg2.png) no-repeat top center;*/
}

.result {
  position: fixed;
  max-height: 70%;
  width: 96%;
  left: 2%;
  background-color: rgba(255, 255, 255, 0);
}

.result .resulttx {
  /*background: url(../image/1.png) no-repeat top center;*/
  background-size: 100%;
  height: 100%;
  padding: 0;
}

.result .resultbg {
  background-size: 100%;
  height: 100%;
  padding: 0 0 0.2rem 0;
}

.result .resultcnt {
  max-height: 85%;
  padding: 60% 0 0 0;
}

.result .resultbtn {
  padding: 0;
}

.result p {
  text-align: center;
}

.result p img {
  width: 60%
}

.result h1 {
  font-weight: 100;
  font-size: 0.5rem;
  margin-bottom: 0.2rem;
}

.result ul img {
  max-width: 36%;
  margin: 0 auto;
}

.rotary ul li {
  margin-left: -0.6rem;
  border-left: 0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
  border-top: 2.5rem solid red;
  transform-origin: 0.6rem 2.5rem;
  -webkit-transform-origin: 0.6rem 2.5rem;
  -moz-transform-origin: 0.6rem 2.5rem;
  -o-transform-origin: 0.6rem 2.5rem;
}

.selected {
  display: block;
}

#tr2 {
  display: none;
}

#tr4 {
  display: none;
}

#result ul {
  display: none
}


.ewm-win {
  text-align: center;
  top: 20%;
}

.ewm-win img {
  max-width: 100%;
  margin: 0 auto;
}

.ad {
  width: 100%;
  margin-bottom: 0.2rem;
}

.ad img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.tab-nav span {
  width: 25%
}

.showpeople {
  height: 0.6rem;
  font-size: 0.3rem;
  line-height: 0.6rem;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  width: 6.2rem;
  border-radius: 0.3rem;
  margin: 0.3rem auto;
}

.common-alpha {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
}

.core-w {
  overflow: hidden;
}

.common-scroll li {
  margin-top: 2vw;
  padding: 1vw;
  height: 10vw;
  border-bottom: 1px dashed hsla(0deg 2% 23% / 65%);
}

.common-scroll-img {
  float: left;
}

.common-scroll-cont {
  float: left;
  width: 85%;
  margin: 0.08rem 0.3rem;
}

.common-scroll-cont-up {
  width: 100%;
}

.common-scroll-cont-lt {
  width: 60%;
  float: left;
}

.common-scroll-cont-rg {
  width: 40%;
  float: right;
}

.common-scroll-cont-dn {
  width: 100%;
  color: rgb(212 17 39);
  ;

}

.shownone {
  display: none;
}

.showblock {
  display: block;
}

.ovflhidden {
  overflow: hidden;
}

.fr {
  color: red;
}

.fr2 {
  color: #ba1212
}

.fb {
  color: blue;
}

.wp100 {
  width: 100%;
}

.wp60 {
  width: 60%;
}

.wp48 {
  width: 48%;
}

.finger {
  opacity: 1;
  transition-delay: 1.5s;
  -webkit-transition-delay: 1.5s;
  -moz-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  position: absolute;
  z-index: 99;
  pointer-events: none;
  left: 42vw;
  top: 43vw;
  width: 15vw;
  height: 15vw;
  background: url("../assets/img/finger.png") no-repeat center center;
  background-size: 100%;
}
.finger_rainbow {
  animation: 1s rainbow infinite;
}
</style>
