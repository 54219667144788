export const prizeList = [
  {
    // icon: require("../assets/img/1.jpg"), // 奖品图片
    imgUrl: "https://file-center.ehuigu.com/lucky-open/packet/1000.png", // 奖品图片
    name: "一等奖", // 奖品名称
    description: "1000",
    prizeId: 1 // 该奖项是否为奖品
  },
  {
    imgUrl: "https://file-center.ehuigu.com/lucky-open/packet/500.png",
    name: "二等奖",
    description: "500",
    prizeId: 1
  },
  {
    imgUrl: "https://file-center.ehuigu.com/lucky-open/packet/200.png",
    name: "三等奖",
    description: "200",
    prizeId: 1
  },
  {
    imgUrl: "https://file-center.ehuigu.com/lucky-open/packet/1-5.png",
    name: "参与奖",
    description: "现金红包1-5元",
    prizeId: 1
  },
  // {
  //   icon: require("../assets/img/5.png"),
  //   name: "五等奖",
  //   remark: "幸运现金红包",
  //   isPrize: 1
  // },
  // {
  //   icon: require("../assets/img/6.jpg"),
  //   name: "六等奖",
  //   remark: "嘉宾套盒一盒",
  //   isPrize: 1
  // }
]
